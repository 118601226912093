import React from "react"

import Layout from "../parts/layout"
import Seo from "../parts/seo"

const CardsPage = () => {
  return (
    <Layout>
      <Seo title="Public Stack Reflection Cards" />
      <div className="row">
        <h1 className="page-title">Public Stack Reflection Cards</h1>
        <div className="columns-2">
          <div className="column">
            
            <p className="page-introduction">The Public Stack Reflection Cards prompt reflection about your technology, its development, and the values it promotes. They are best used together with a team in a live setting.</p>
            <a className="button" href="/public-stack-reflection-cards-print.pdf">Download PDF and print your set of cards</a>
          </div>
          <div className="column">
            <img className="responsive-image" src="/public-stack-reflection-cards.jpg" alt="Photo of the Public Stack Reflection Cards deck showing the fout categories Foundation, Design Process, Technology and People & Planet" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CardsPage
